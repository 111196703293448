// Copyright 2021
// ThatWorks.xyz Limited

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Capitalize } from '@thatworks/string-utils';
import { Box, Button, Card, CardBody, CardHeader, Heading, Spinner, Text } from 'grommet';
import React, { useEffect, useState } from 'react';
import { loginRequest } from '../../azureAuthConfig';
import AuthenticatedPage from '../../components/AuthenticatedPage';

enum DeploymentType {
    WEB_APP = 'Web App',
    FUNCTION = 'Function App',
}

interface SlotAttributes {
    id: string;
    defaultHostName: string;
    version?: { hash: string; date: Date };
    newest?: boolean;
}

interface AzureResource {
    name: string;
    deploymentType: DeploymentType;
    slots: Map<string, SlotAttributes>;
    prodSlotUrl: string;
    lastSwap?: Date;
    deployment: string;
}

interface AzureResources {
    subscriptionId: string;
    baseUrl: string;
    resources: Map<string, AzureResource[]>;
}

function getDeploymentTypeName(type: DeploymentType) {
    switch (type) {
        case DeploymentType.FUNCTION:
            return 'FunctionApp';
        case DeploymentType.WEB_APP:
            return 'WebApp';
    }
}

function getDeploymentType(value: string) {
    if (value.includes('app') && !value.includes('function')) {
        return DeploymentType.WEB_APP;
    } else if (value.includes('function')) {
        return DeploymentType.FUNCTION;
    } else {
        throw new Error('Unknown deployment kind');
    }
}

const PRODUCTION_SLOT_KEY = 'production';

const LOCALE_STRING_OPTIONS: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    day: 'numeric',
    month: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

function Home(): JSX.Element {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [azureResources, setAzureResources] = useState<AzureResources>();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [authToken, setAuthToken] = useState('');
    const [swapMessage, setSwapMessage] = useState<{ resourceIdx: number; messg: string } | undefined>();

    async function getVersionFromHtml(url: string): Promise<SlotAttributes['version']> {
        let hash: string | null | undefined;
        let date: string | null | undefined;

        try {
            const res = await fetch(url, { method: 'GET' });
            const txt = await res.text();
            const html = new DOMParser().parseFromString(txt, 'text/html');
            const hashElement = html.querySelector('meta[name="build-vcs-hash"]');
            if (hashElement) {
                hash = hashElement.getAttribute('content');
            }
            const dateElement = html.querySelector('meta[name="build-date"]');
            if (dateElement) {
                date = dateElement.getAttribute('content');
            }

            if (!date || !hash) {
                return undefined;
            }
            return { hash: hash, date: new Date(date) };
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    async function getVersionFromFunctionApp(url: string): Promise<SlotAttributes['version']> {
        try {
            const res = await fetch(`${url}/version`, { method: 'GET' });
            const json = await res.json();
            if (!json || !json.commit || !json.date) {
                return undefined;
            }
            return { hash: json.commit, date: new Date(json.date) };
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    async function fetchData(token: string) {
        setHasLoaded(false);
        setAuthToken(token);
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append('Authorization', bearer);

        const azAuthoptions = {
            method: 'GET',
            headers: headers,
        };

        // APIs: https://resources.azure.com/
        const azResources: AzureResources = {
            resources: new Map(),
            subscriptionId: '',
            baseUrl: 'https://management.azure.com',
        };

        // Subscription ID
        {
            const res = await fetch('https://management.azure.com/subscriptions?api-version=2014-04-01', azAuthoptions);
            const j = await res.json();
            azResources.subscriptionId = j.value[0].subscriptionId;
        }

        // Site information
        {
            const res = await fetch(
                `https://management.azure.com/subscriptions/${azResources.subscriptionId}/providers/Microsoft.Web/sites?api-version=2020-12-01`,
                azAuthoptions,
            );
            const j = await res.json();
            (j.value as Array<any>).forEach((v) => {
                const deployment = v.tags?.deployment || 'unknown';
                const r: AzureResource = {
                    name: v.name,
                    deploymentType: getDeploymentType(v.kind),
                    lastSwap: v.properties.slotSwapStatus
                        ? new Date(v.properties.slotSwapStatus.timestampUtc)
                        : undefined,
                    slots: new Map([
                        [
                            PRODUCTION_SLOT_KEY,
                            {
                                id: v.id,
                                defaultHostName: `https://${v.properties.defaultHostName}`,
                            },
                        ],
                    ]),
                    prodSlotUrl: `https://management.azure.com${v.id}/slots?api-version=2020-12-01`,
                    deployment,
                };

                const found = azResources.resources.get(deployment);
                if (found) {
                    found.push(r);
                } else {
                    azResources.resources.set(deployment, [r]);
                }
            });
        }

        // Slots
        const resourceArray = Array.from(azResources.resources.values()).flat();
        const slots = await Promise.all(resourceArray.map((v) => fetch(v.prodSlotUrl, azAuthoptions)));

        for (let i = 0; i < slots.length; i++) {
            const j = await slots[i].json();
            (j.value as Array<any>).forEach((v) => {
                resourceArray[i].slots.set(v.name.split('/')[1], {
                    id: v.id,
                    defaultHostName: `https://${v.properties.defaultHostName}`,
                });
            });
        }

        // Versioning
        for (const [, r] of azResources.resources) {
            for (const v of r) {
                const slotsArray = Array.from(v.slots.values());
                const versions = await Promise.all(
                    slotsArray.map((slot) =>
                        v.deploymentType === DeploymentType.WEB_APP
                            ? getVersionFromHtml(slot.defaultHostName)
                            : getVersionFromFunctionApp(slot.defaultHostName),
                    ),
                );
                versions.forEach((version, i) => (slotsArray[i].version = version));
            }
        }
        // Find newest slot
        for (const [, r] of azResources.resources) {
            for (const v of r) {
                let newestDate: Date | undefined;
                let newestSlot: string | undefined;
                for (const [slotKey, slot] of v.slots) {
                    if (!newestDate || (slot.version && slot.version.date > newestDate)) {
                        newestDate = slot.version?.date;
                        newestSlot = slotKey;
                    }
                }
                const slot = v.slots.get(newestSlot || '');
                if (slot) {
                    slot.newest = true;
                }
            }
        }

        setAzureResources(azResources);
        setHasLoaded(true);
    }

    useEffect(() => {
        if (isAuthenticated) {
            instance
                .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
                .then((response) => {
                    fetchData(response.accessToken);
                })
                .catch((e) => {
                    console.error(e);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, instance, isAuthenticated]);

    function renderResourceCards(azureResources: AzureResources): React.ReactNode {
        return Array.from(azureResources.resources.entries()).map(([d, r], ri) => (
            <Box>
                <Heading level={4} key={`deployment-${ri}`} style={{ textTransform: 'capitalize' }}>
                    Deployment: {d}
                </Heading>
                <Box direction="row" gap="medium" wrap>
                    {r.map((v, vi) => (
                        <Card
                            background="light"
                            key={`resource-${vi}`}
                            elevation="none"
                            border
                            width="medium"
                            margin={{ bottom: 'medium' }}
                        >
                            <CardHeader pad="medium">
                                <Box>
                                    <Text weight="bold">
                                        {getDeploymentTypeName(v.deploymentType)}: {v.name}
                                    </Text>
                                </Box>
                            </CardHeader>
                            <CardBody pad={{ bottom: 'medium', left: 'medium', right: 'medium' }}>
                                {v.slots
                                    ? Array.from(v.slots.entries()).map(([slotKey, slotValue], slotIndex) => {
                                          return (
                                              <div key={`slot-${slotIndex}`}>
                                                  <Box direction="row" gap="small">
                                                      <Box>
                                                          <b>
                                                              <a
                                                                  href={slotValue.defaultHostName}
                                                                  target="_blank"
                                                                  rel="noreferrer"
                                                              >
                                                                  {Capitalize(slotKey)} Slot
                                                              </a>
                                                          </b>
                                                      </Box>
                                                      {slotValue.newest && (
                                                          <Box background="#D3D3D3" pad="xsmall">
                                                              <Text size="10px">NEWEST</Text>
                                                          </Box>
                                                      )}
                                                  </Box>
                                                  <p />
                                                  Version: {slotValue.version ? slotValue.version.hash : 'Unavailable'}
                                                  <br />
                                                  Date:{' '}
                                                  {slotValue.version
                                                      ? slotValue.version.date.toLocaleString(
                                                            undefined,
                                                            LOCALE_STRING_OPTIONS,
                                                        )
                                                      : 'Unavailable'}
                                                  <p />
                                                  {renderSwap(slotKey, v, vi)}
                                              </div>
                                          );
                                      })
                                    : ''}
                            </CardBody>
                        </Card>
                    ))}
                </Box>
            </Box>
        ));
    }

    function renderSwap(slotName: string, resource: AzureResource, resourceIdx: number): React.ReactNode {
        return slotName === PRODUCTION_SLOT_KEY ? (
            ''
        ) : (
            <div>
                <b>
                    Last swapped on{' '}
                    {resource.lastSwap
                        ? resource.lastSwap.toLocaleString(undefined, LOCALE_STRING_OPTIONS)
                        : 'Not available'}
                </b>
                <p />
                <Button hoverIndicator="light-1" active onClick={() => handleSwap(resource, resourceIdx, slotName)}>
                    <Box pad="small" direction="row" align="center" gap="small">
                        <Text size="small">Swap {getDeploymentTypeName(resource.deploymentType)} Now</Text>
                    </Box>
                </Button>
                <Text size="small">
                    {swapMessage && swapMessage.resourceIdx === resourceIdx ? `  ${swapMessage.messg}` : ''}
                </Text>
                <p />
            </div>
        );
    }

    async function handleSwap(resource: AzureResource, resourceIdx: number, targetSlotName: string) {
        const slot = resource.slots.get(PRODUCTION_SLOT_KEY);
        if (!slot) {
            throw new Error('No productions slot');
        }

        setSwapMessage({ resourceIdx: resourceIdx, messg: 'Swapping..' });

        const headers = new Headers();
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        const res = await fetch(`https://management.azure.com${slot.id}/slotsswap?api-version=2021-02-01`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                preserveVnet: false,
                targetSlot: targetSlotName,
            }),
        });
        if (res.status === 200) {
            setSwapMessage({ resourceIdx: resourceIdx, messg: 'Done!' });
        } else if (res.status === 202) {
            setSwapMessage({
                resourceIdx: resourceIdx,
                messg: 'Accepted, check Portal',
            });
        } else {
            setSwapMessage({
                resourceIdx: resourceIdx,
                messg: `Failed: ${res.status}`,
            });
        }
    }

    return (
        <AuthenticatedPage>
            <Box pad="medium">
                {azureResources && hasLoaded ? (
                    <div>
                        <Heading level={3}>Deployment Status</Heading>
                        <Box direction="row" gap="medium" wrap>
                            {renderResourceCards(azureResources)}
                        </Box>
                    </div>
                ) : (
                    <Spinner size="medium" />
                )}
            </Box>
        </AuthenticatedPage>
    );
}

export default Home;
