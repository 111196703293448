// Copyright 2021
// ThatWorks.xyz Limited

import { ApolloExplorer } from '@apollo/explorer/react';
import { buildClientSchema, getIntrospectionQuery, printSchema } from 'graphql';
import { Box, Button, Spinner, Text, TextArea } from 'grommet';
import { useCallback, useEffect, useState } from 'react';
import AuthenticatedPage from '../../components/AuthenticatedPage';

function isDev() {
    return process.env.NODE_ENV === 'development';
}

const ACCESS_TOKEN_HEADER = 'Authorization';

async function getSchema(endpoint: string, accessToken: string) {
    const headers: HeadersInit = {
        'Content-Type': 'application/json',
    };
    headers[ACCESS_TOKEN_HEADER] = `Bearer ${accessToken}`;
    const response = await fetch(endpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify({ query: getIntrospectionQuery() }),
    });
    if (!response.ok) {
        return {
            error: response.statusText,
        };
    }

    const graphqlSchemaObj = buildClientSchema((await response.json()).data);
    return {
        schema: printSchema(graphqlSchemaObj),
    };
}

function TokenInput(props: { onSubmit: (token: string) => void }): JSX.Element {
    const [token, setToken] = useState<string | undefined>();

    return (
        <Box pad="medium" width="large" gap="xsmall">
            <TextArea
                placeholder="Paste access token"
                value={token}
                onChange={(event) => setToken(event.target.value)}
            />
            <Box direction="row">
                <Button
                    disabled={token === undefined}
                    primary
                    label="Submit"
                    onClick={() => {
                        if (!token) {
                            return;
                        }
                        props.onSubmit(token);
                    }}
                />
            </Box>
        </Box>
    );
}

function GraphQlExplorerBase(props: { mode: 'staging' | 'prod' }) {
    const [token, setToken] = useState<string | undefined>();
    const [schema, setSchema] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>();

    const getEndpoint = useCallback(() => {
        if (isDev()) {
            return 'http://localhost:7071/api/graphql';
        }
        if (props.mode === 'prod') {
            return 'https://app.thatworks.ai/api/graphql/';
        }
        if (props.mode === 'staging') {
            return 'https://app.staging.thatworks.ai/api/graphql/';
        }
        return '';
    }, [props.mode]);

    useEffect(() => {
        if (!token) {
            return;
        }

        getSchema(getEndpoint(), token)
            .then((res) => {
                if (res.schema) {
                    setSchema(res.schema);
                } else if (res.error) {
                    setError(res.error);
                }
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setError(e.message);
                } else {
                    setError('Failed');
                }
            });
    }, [getEndpoint, token]);

    if (!token) {
        return <TokenInput onSubmit={(t) => setToken(t)} />;
    }

    if (schema) {
        return (
            <ApolloExplorer
                className="explorer-container"
                schema={schema}
                endpointUrl={getEndpoint()}
                persistExplorerState={false}
                initialState={{
                    displayOptions: {
                        showHeadersAndEnvVars: true,
                        docsPanelState: 'open',
                        showGlobalHeader: false,
                    },
                }}
                handleRequest={(endpointUrl, options) => {
                    const headers: HeadersInit = { ...options.headers };
                    headers[ACCESS_TOKEN_HEADER] = `Bearer ${token}`;
                    return fetch(endpointUrl, {
                        ...options,
                        headers,
                    });
                }}
            />
        );
    }

    return (
        <Box pad="medium">
            {error && (
                <Box pad="small" background={{ color: 'status-warning', opacity: 'medium' }} width="medium">
                    <Text>{error}</Text>
                </Box>
            )}
            {!schema && !error && <Spinner alignSelf="center" justify="center" />}
        </Box>
    );
}

function getHeading(mode: 'staging' | 'prod') {
    let res = 'Prod QL';
    if (mode === 'staging') {
        res = 'Staging QL';
    }
    if (isDev()) {
        res = `[Dev] ${res}`;
    }
    return res;
}

export default function GraphQlExplorer(props: { mode: 'staging' | 'prod' }) {
    return (
        <AuthenticatedPage heading={getHeading(props.mode)}>
            <GraphQlExplorerBase mode={props.mode} />
        </AuthenticatedPage>
    );
}
