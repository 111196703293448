// Copyright 2021
// ThatWorks.xyz Limited

import { Grommet } from 'grommet';
import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Pages } from './pages';
import GraphQlExplorer from './pages/graphql';
import Home from './pages/home';

function App(): JSX.Element {
    const theme = {
        global: {
            colors: {
                brand: '#228BE6',
            },
            font: {
                family: 'Roboto',
                size: '14px',
                height: '20px',
            },
        },
    };

    return (
        <Grommet theme={theme} full id="grommet-theme">
            {/* using HashRouter because github pages does not redirect all routes to index.html */}
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path={`/${Pages.StagingQl}`} element={<GraphQlExplorer mode={'staging'} />} />
                    <Route path={`/${Pages.ProdQl}`} element={<GraphQlExplorer mode={'prod'} />} />
                </Routes>
            </HashRouter>
        </Grommet>
    );
}

export default App;
