// Copyright 2021
// ThatWorks.xyz Limited

import React from 'react';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { msalConfig } from './azureAuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>,
);
