// Copyright 2021
// ThatWorks.xyz Limited

import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Anchor, Box, Button, Header, Heading } from 'grommet';
import React from 'react';
import { loginRequest } from '../azureAuthConfig';
import { Pages } from '../pages';

export default function AuthenticatedPage(props: { heading?: string; children?: React.ReactNode }): JSX.Element {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    function handleLogin() {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error(e);
        });
    }

    function handleLogout() {
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    }

    return (
        <Box fill>
            <Header background="brand" pad={{ left: 'medium', right: 'medium', vertical: 'xxsmall' }}>
                <Heading level={3}>{props.heading || `That Works Dev`}</Heading>
                <Box direction="row" align="center" gap="small">
                    {isAuthenticated && (
                        <Box direction="row" align="center" gap="small">
                            <Anchor href={`/#/${Pages.StagingQl}`}>Staging Explorer</Anchor>
                            <Anchor href={`/#/${Pages.ProdQl}`}>Prod Explorer</Anchor>
                        </Box>
                    )}
                    <Button
                        label={isAuthenticated ? 'Logout' : 'Login'}
                        onClick={() => (isAuthenticated ? handleLogout() : handleLogin())}
                    />
                </Box>
            </Header>
            <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
        </Box>
    );
}
